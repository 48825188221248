import './App.css';
import {BrowserRouter, Routes, Route, MemoryRouter, useNavigate} from 'react-router-dom'
import Starting from './aStartingPage';


function App() {
    return(
      <MemoryRouter>
        <Starting/>
      </MemoryRouter>
    )
}

export default App;
