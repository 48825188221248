import { RColumn, RText, RTitile, Space } from "../compnents/otherCOmps";
import logoImg from "../assets/logo.png";
import { versionGame } from "../global/const";

export default function NoActivePage({msg}){
    return (
        <RColumn>
            <Space height="40px"/>
            <img src={logoImg} width={"150px"}/>
            <Space height="40px"/>
            <RTitile>Currently offline</RTitile>
            <Space/>
            <RText>{msg}</RText>
        </RColumn>
    )
}