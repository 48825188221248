import { InputField, LoadingScreen, PrimaryBtn, RColumn, RRow, RText, RTitile, SecondaryBtn, Space, SpaceHorizontal } from "../compnents/otherCOmps";
import copyIcon from "../assets/copy.png";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import { BaseInfo, ColorPalette, StatusInfo, isTest } from "../global/const";
import { format } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../compnents/errorMessagesComp";

export default function WalletPage({wallet, balances}){

    const [lastDeposits, setLastDepoists] = useState([]);
    const [isDeposit, setIsDeposit] = useState(true);
    const [isWidthrawing, setIsWidrawing] = useState(false); 
    const [widAmount, setWidAmount] = useState("");
    const [widAddress, setWidAddress] = useState("");
    const [selectedChain, setSelectedChain] = useState(BaseInfo.ethChainID);
    const [lastWid, setLastWid] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

   const fixedFee = {
    [BaseInfo.ethChainID]: 0.002,
    [BaseInfo.arbChainID]: 0.001,
   }

   const minEth = 0.003;
    useEffect(() => {
       getDesposits();
       getWid();
    },[]);

    async function getDesposits(){
        const depositsRef = collection(db, "users", auth.currentUser.uid, "deposits");
        const q = query(depositsRef,orderBy("date", "desc"), limit(5));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty) return;
        let newDocs = [];
        querySnapshot.docs.forEach((doc) => {
            newDocs.push(doc.data());
        });
        setLastDepoists(newDocs);
    }

    async function getWid(){
        const depositsRef = collection(db, "users", auth.currentUser.uid, "withdrawals");
        const q = query(depositsRef,orderBy("date", "desc"), limit(5));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty) return;
        let newDocs = [];
        querySnapshot.docs.forEach((doc) => {
            newDocs.push(doc.data());
        });
        setLastWid(newDocs);
    }
    function copyAddress(){
        navigator.clipboard.writeText(wallet);
        toast.success("Address copied", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
          });
    }

    function SingleDeposit({deposit}){
        return(
            <div style={{width: "100%"}}>
            <div style={{border: "2px solid " + ColorPalette.accentColor, borderRadius: "8px", padding: "12px", marginBottom: "8px"}}>
                <RColumn width={"100%"} verticalAlignment="start" horizontalAlign="start">
                    <RRow width={"100%"} horizontalAlign="space-between">
                        <RText>{format(deposit.date, 'iii d MMM HH:mm')}</RText>
                        <RText color={deposit.confirmed ? "green" : "orange"}>{deposit.confirmed ? "Confirmed" : "Pending"}</RText>
                    </RRow>
                   <Space height="10px"/>
                   <RText weight="bold" size="20px">{deposit.valueWithDecimals} {BaseInfo.chainTokensData[deposit.erc20Contract ?? deposit.chainID]?.symbol ?? "-"}</RText>
                   <RText>{BaseInfo.chainTokensData[deposit.chainID].name}</RText>
                   <RText size="17px">from {deposit.from.slice(0,7)}...{deposit.from.slice(-5)}</RText>
                </RColumn>
            </div>
            </div>
        )
    }
    function SingleWId({wid}){
        return(
            <div style={{width: "100%"}}>
            <div style={{border: "2px solid " + ColorPalette.accentColor, borderRadius: "8px", padding: "12px", marginBottom: "8px"}}>
                <RColumn width={"100%"} verticalAlignment="start" horizontalAlign="start">
                    <RRow width={"100%"} horizontalAlign="space-between">
                        <RText>{format(wid.date, 'iii d MMM HH:mm')}</RText>
                        <RText color={wid.confirmed ? "green" : "orange"}>{wid.confirmed ? "Confirmed" : "Pending"}</RText>
                    </RRow>
                   <Space height="10px"/>
                   <RText weight="bold" size="20px">{wid.amountDecimal} {BaseInfo.chainTokensData[wid.erc20Contract ?? wid.chainID]?.symbol ?? "-"}</RText>
                   <RText>{BaseInfo.chainTokensData[wid.chainID].name}</RText>
                   <RText size="17px">to {wid.toAddress.slice(0,7)}...{wid.toAddress.slice(-5)}</RText>
                </RColumn>
            </div>
            </div>
        )
    }

    function Deposits(){
        return(
            <>
            {lastDeposits.length === 0 && <RText>No deposits</RText>}
            {lastDeposits.map((dep, ind) => (
                <SingleDeposit key={ind} deposit={dep}/>
            ))}
            </>
        )
    }
    function handleInputChange(event) {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9.]/g, '');
          // Assicurarsi che ci sia solo un punto decimale
          const dotCount = inputValue.split('.').length - 1;
          if (dotCount > 1) {
              inputValue = inputValue.slice(0, -1);
          }
  
        setWidAmount(inputValue);
    }
    function Widthdrawal(){
        if(StatusInfo.data.withdrawal === false && isTest === false){
            return(
                <>
                <RText size="18px" align={"center"}>Withdrawals are temporarily disabled</RText>
                <Space height="8px"/>
                <RText>{StatusInfo.data.withdrawal_msg}</RText>
                </>
            )
        }
        return(
            <>
                  <SecondaryBtn onClick={() => setIsWidrawing(true)} text={"Withdraw"}/>    
                  <Space/>
                  {lastWid.length === 0 && <RText>No withdrawals</RText>}
                {lastWid.map((dep, ind) => (
                <SingleWId key={ind} wid={dep}/>
            ))}  
            </>
        )
    }
    
    async function preleva(){
        console.log(parseFloat(widAmount));
        if(widAddress.length < 5){
            showErrorNotification("Enter an address");
            return;
        }
        try {
            setIsLoading(true);
            const funct = httpsCallable(functions, "withdrawFunds");
            const dat = {
                chainID: selectedChain,
                native: true,
                tokenAddress: null,
                toAddress: widAddress,
                amount: parseFloat(widAmount),
            }
            console.log(dat);
            const res = await funct(dat);
            console.log(res.data);
        } catch (error) {
            console.log(error);
            showErrorNotification("There was an error");
        }
        setIsLoading(false);
    }
    return(
        <>
       
       {isWidthrawing &&  <div style={{position: "fixed", width: "100%", height: "100vh", backgroundColor: "rgba(0,0,0,0.7)"}}>
                <div style={{width: "100%", height:"80%",position: "fixed", backgroundColor: ColorPalette.bgColor, bottom: "0px"}}>
                    <RColumn padding={"13px"}>
                        <div onClick={() => setIsWidrawing(false)} style={{right: "16px", position:"absolute"}}><RText>X</RText></div>
                        <Space/>
                        <RTitile>Widthdrawal</RTitile>
                        <Space/>
                        <InputField placeHolder={"Reciver addrress"} value={widAddress} setValue={(v) => {setWidAddress(v.target.value)}}/>
                        <Space/>
                        <RRow>
                            <div onClick={() => setSelectedChain(BaseInfo.ethChainID)} style={{width: "100%",border: selectedChain === BaseInfo.ethChainID ? "2px solid " + ColorPalette.accentColor : "2px solid rgba(0, 0, 0, 0)", padding: "8px", margin: "3px 0px", borderRadius: "10px"}}>
                                <RText>{BaseInfo.chainTokensData[BaseInfo.ethChainID].name}</RText>
                            </div>
                            <SpaceHorizontal/>
                            <div onClick={() => setSelectedChain(BaseInfo.arbChainID)}  style={{width: "100%",border: selectedChain === BaseInfo.arbChainID ? "2px solid " + ColorPalette.accentColor : "2px solid rgba(0, 0, 0, 0)", padding: "8px", margin: "3px 0px", borderRadius: "10px"}}>
                                <RText>{BaseInfo.chainTokensData[BaseInfo.arbChainID].name}</RText>
                            </div>
                        </RRow>
                        <Space/>
                        <InputField placeHolder={"Amount"} value={widAmount.toString()} setValue={handleInputChange}/>
                        <RRow width={"100%"} horizontalAlign="space-between">
                            <RText size="15px" color={widAmount < minEth ? "red" : "white"}>MIN 0.003 ETH</RText>
                            <div onClick={() => setWidAmount(balances[selectedChain].native.balance)}>
                                <RText>Use Max</RText>
                            </div>
                        </RRow>
                        {widAmount > balances[selectedChain].native.balance && <RText color="red">Surpassed max of  {balances[selectedChain].native.balance.toFixed(5)}</RText>}
                        <Space/>
                        <RText>A fixed {fixedFee[selectedChain]} ETH will be deducted for the deposit and widthdrawal fee</RText>
                        <Space/>
                        {widAmount >= minEth && widAmount <= balances[selectedChain].native.balance && <PrimaryBtn onClick={preleva} text={"Withdraw"}/>}
                    </RColumn>
                </div>
        </div>}
        <div style={{width: "100%"}}>
        <div style={{padding: "20px"}}>
        <RColumn horizontalAlign="start" width={"100%"}>
            <RText>Wallet:</RText>
            <div onClick={copyAddress} style={{cursor: "pointer"}}>
                <RRow>
                    <img src={copyIcon} width={"14px"}/>
                    <SpaceHorizontal width="4px"/>
                    <RText weight="bold" size="14px">{wallet}</RText>
                </RRow>
            </div>
            <RText>Minimum ETH: 0.003 ETH</RText>
            <RText>! ONLY send ETH from Ethereum and Arbitrum Blockchain</RText>
            <RText>! ONLY send {BaseInfo.chainTokensData[BaseInfo.gameTokenAddress].symbol} from Ethereum Blockchain</RText>
            
            <Space/>
            {Object.keys(balances).map((key) =>(
                <div key={"k" + key}>
                    <RText>{BaseInfo.chainTokensData[key]?.name}</RText>
                    <RRow>
                        <RText weight="bold" size="20px">{balances[key]?.native?.balance?.toFixed(5)}</RText>
                        <SpaceHorizontal width="4px"/>
                        <RText weight="bold" size="16px">{BaseInfo.chainTokensData[key]?.symbol}</RText>
                    </RRow>
                    {Object.keys(balances[key]?.tokens ?? {}).map((keyToken) => (
                          <RRow key={keyToken}>
                          <RText weight="bold" size="20px">{balances[key].tokens[keyToken].balance?.toFixed(2)}</RText>
                          <SpaceHorizontal width="4px"/>
                          <RText weight="bold" size="16px">{BaseInfo.chainTokensData[keyToken.toLowerCase()]?.symbol}</RText>
                      </RRow>
                    ))}
                </div>
            ))}
            <Space/>
            <RRow width={"100%"} horizontalAlign="space-between">
                <div onClick={() => setIsDeposit(true)} style={{width: "50%",border: isDeposit ? "2px solid " + ColorPalette.accentColor : "2px solid transparent", padding: "6px 22px", borderRadius: "12px"}}>
                    <RTitile align={"center"}>Deposit</RTitile>
                </div>
                <div onClick={() => setIsDeposit(false)} style={{width: "50%",border: !isDeposit ? "2px solid " + ColorPalette.accentColor : "2px solid transparent", padding: "6px 22px", borderRadius: "12px"}}>
                    <RTitile  align={"center"}>Withdraw</RTitile>
                </div>
            </RRow>
            <Space height="32px"/>
           {isDeposit ? 
            <Deposits/>
            :
            <Widthdrawal/>
            }
            
        </RColumn>
        </div>
        </div>
        {isLoading && <LoadingScreen/>}
        </>
    )
}