import { toast } from "react-toastify";
import { RColumn, RRow, RText, RTitile, Space, SpaceHorizontal } from "../compnents/otherCOmps";
import copyIcon from "../assets/copy.png";
import { useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

export default function ReferralPage({refCode}){

    const linkRef = "https://t.me/TheGameHubBot?start=ref=" + refCode;

    const [numInvited, setNumInvited] = useState();

    useState(() => {
        getData();
    },[]);

    async function getData(){
        const referralRef = doc(db, "referrals", auth.currentUser.uid);
        const refDoc = await getDoc(referralRef);
        if(!refDoc.exists()) return;
        setNumInvited(refDoc.data()["numReferrals"] ?? 0);
    }

    function copyCode(){
        navigator.clipboard.writeText(linkRef);
        toast.success("Referral code copied", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
          });
    }

    return(
        <>
        <RColumn horizontalAlign="start" width={"100%"}>
            <div style={{padding: "20px"}}>
                <RText>Referral code:</RText>
                <SpaceHorizontal/>
                <div onClick={copyCode}>
                <RRow width={"100%"}>
                    <img src={copyIcon} width={"15px"}/>
                    <SpaceHorizontal/>
                    <RText weight="bold" size="15px">{linkRef}</RText>
                </RRow>
                </div>
                <Space/>
                <RText><strong style={{fontSize: "22px"}}>{numInvited ?? "-"}</strong> Users referred</RText>
            </div>
        </RColumn>
        </>
    )
}