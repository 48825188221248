import { toast } from "react-toastify";

export const showErrorNotification = (errorMessage, time = 3000) => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
    });
};
export const showSuccessNotification = (errorMessage) => {
  toast.success(errorMessage, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
  });
};