import { useLocation, useNavigate } from "react-router-dom";
import { SecondaryBtn } from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { useEffect, useState } from "react";


export default function F2PGames() {
    let [h, setH] = useState(window.innerHeight);
    const nav = useNavigate();
    const location = useLocation();
    const link = location.state.link;

    function back(){
        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
    }
    const handleResize = () => {
        setH(window.innerHeight);
      };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

  return(
    <>
        <div style={{position: "fixed", top: "30px", left:"10px"}}>
        <SecondaryBtn onClick={back} text={"Exit"}></SecondaryBtn>
        </div>
        <iframe height={h} width="100%" src={link} style={{border: "none"}} ></iframe>
    </>
  )
}