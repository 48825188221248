import { useEffect, useState } from "react"
import { HomePage } from "./homePage";
import profileIcon from "../assets/profile2.png";
import gamesIcon from "../assets/games.png";
import refIcon from "../assets/referrla.png";
import walletIcon from "../assets/wallet.png";
import { RColumn, RRow, RText, Space, SpaceHorizontal } from "../compnents/otherCOmps";
import { BaseInfo, ColorPalette, setUserBalances } from "../global/const";
import headerImg from "../assets/headerbBG.jpeg";
import logoImg from "../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import copyIcon from "../assets/copy.png";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
//import { getRealBalance } from "../global/util";
import ProfilePage from "../profile/profilePage";
import WalletPage from "../wallet/walletPgae";
import ReferralPage from "../referral/referralPages";
import friendsIcon from "../assets/friends.png";
import FriendsPage from "../friends/friendsPage";
import { getPriceToken } from "../global/apis";

export default function TabController({tgUsername}){

    const [currentTab, setCurrentTab] = useState(0);
    const [wallet, setWallet] = useState("");
    const [balances, setBalances] = useState({});
    const [refCode, setRefCode] = useState("");

    const [isMobile, setIsMobile] = useState(true);

    const [totUsdBalance, setTotUsdBalance] = useState(0);

    const pages = [
        {icon: gamesIcon, label: "Games", index: 0},
        {icon: refIcon, label: "Referral", index: 1},
        {icon: walletIcon, label: "Wallet", index: 2},
        {icon: friendsIcon, label: "Friends", index: 3},
    ]

    useEffect(() => {
        setIsMobile(window.innerWidth < 600);
        window.addEventListener("resize", handleResize);
        const userDoc = doc(db, "users", auth.currentUser.uid);

        const unsub = onSnapshot(userDoc, (snapshot) => {
                if(snapshot.exists())
                    updateData(snapshot.data());
        });
        return () => unsub();
    },[]);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };
    const updateData = async (data) => {
        if(!data) return;
        setRefCode(data["code"] ?? "");
        setWallet(data["address"]);
        //const realB = await getRealBalance();
        setBalances(data["balances"]);
        setUserBalances(data["balances"]);
        getTotBalance(data["balances"]);
    };

    function Page(){
        if(currentTab === 0) return <HomePage tgUsername={tgUsername}/>
        if(currentTab === 3) return <FriendsPage/>
        if(currentTab === 2) return <WalletPage wallet={wallet} balances={balances}/>
        if(currentTab === 1) return <ReferralPage refCode={refCode}/>
        if(currentTab === 4) return <ProfilePage tgUsername={tgUsername}/>
    }
    function SingleTabBtn({page}){
        return(
            <>
            <div style={{cursor: "pointer"}} onClick={() => setCurrentTab(page.index)}>
            <RColumn>
             <img src={page.icon} width={"28px"}/>
             <Space height="4px"/>
             <RText weight="bold" color={page.index === currentTab ? ColorPalette.accentColor : "white"}>{page.label}</RText>
             </RColumn>
             </div>
            </>
        )
    }
    function SingleTabBtnDesktop({page}){
        return(
            <>
            <div style={{cursor: "pointer", margin: "12px 0px"}} onClick={() => setCurrentTab(page.index)}>
            <RRow horizontalAlign="start" width={"120px"} verticalAlignment="center">
             <img src={page.icon} width={"26px"}/>
             <SpaceHorizontal height="6px"/>
             <RText size="17px" weight="bold" color={page.index === currentTab ? ColorPalette.accentColor : "white"}>{page.label}</RText>
             </RRow>
             </div>
            </>
        )
    }

    async function getTotBalance(balansss){
        let totUsdBal = 0;
        const ethPrice = await getPriceToken("ETH");
        Object.keys(balansss).map((key, ind) => {
            if(key === BaseInfo.ethChainID || key === BaseInfo.arbChainID){
                totUsdBal += (balansss[key].native.balance ?? 0) * ethPrice;
            }
        });
       const ghubT = balansss[BaseInfo.ethChainID]?.tokens[BaseInfo.gameTokenAddress]?.balance ?? 0;
       totUsdBal += ghubT * BaseInfo.baseInfoDoc.usdPerGameToken;
       setTotUsdBalance(totUsdBal);
    }

    function TotalBalance(){
        return(
            <RText size="17px"><strong>{totUsdBalance.toFixed(2)}</strong> USD</RText>
        )
    }
    if(!isMobile){
        return(
            
            <>
              <div style={{zIndex: 10, position: "fixed", width: "300px", height: "100vh", backgroundColor: ColorPalette.tabColor, top: 0, left:0}}>
                    <div style={{backgroundColor: ColorPalette.tabColor, padding: "10px 30px", backgroundImage: `url(${headerImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                        <RRow height="100%" horizontalAlign="space-between">
                            <RRow>
                                <img src={logoImg} height="60px"></img>
                                <SpaceHorizontal/>
                                <RColumn horizontalAlign="start">
                                    <RText weight="bold" size="18px">{tgUsername.slice(0,10)}</RText>
                                    <TotalBalance/>
                                </RColumn>
                            </RRow>
                            <img src={profileIcon} width={"25px"} onClick={() => setCurrentTab(4)}/>
                        </RRow>
                    </div>
                    <Space/>
                    <RColumn width={"100%"} horizontalAlign="center" verticalAlignment="start" height={"100%"}>
                        {pages.map((page, ind) => (
                            <SingleTabBtnDesktop key={ind} page={page}/>
                        ))}
                    </RColumn>
                </div>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
            <div style={{display:"flex", width:"100%", flexDirection: "row"}}>
                <div style={{width: "300px"}}></div>
                <div style={{flex: 1}}>
                    <Page/>
                </div>
            </div>
            </>
        )
    }
    return(
        <>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <div style={{backgroundColor: ColorPalette.tabColor, padding: "10px 30px", backgroundImage: `url(${headerImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
            <RRow height="100%" horizontalAlign="space-between">
                <RRow>
                <img src={logoImg} height="60px"></img>
                <SpaceHorizontal/>
                <RColumn horizontalAlign="start">
                        <RText weight="bold" size="18px">{tgUsername.slice(0,10) ?? ""}</RText>
                        <TotalBalance/>
                </RColumn>
                </RRow>
                <img src={profileIcon} width={"25px"} onClick={() => setCurrentTab(4)}/>
            </RRow>
            </div>
         <div style={{zIndex: 10, position: "fixed", width: "100%", height: "85px", background: `linear-gradient(to bottom, rgba(14, 22, 27, .95), ${ColorPalette.tabColor})`, bottom: 0, paddingTop: "12px"}}>
            <RRow width={"100%"} horizontalAlign="space-evenly" verticalAlignment="start" height={"100%"}>
                {pages.map((page, ind) => (
                    <SingleTabBtn key={ind} page={page}/>
                ))}
            </RRow>
           
        </div>
        <Page/>
        <Space height="90px"/>
        </>
    )
}