import { useEffect, useRef, useState } from "react"
import { BackToHomeBtn, InputField, LoadingScreen, PrimaryBtn, RColumn, RRow, RText, RTitile, SecondaryBtn, Space, SpaceHorizontal, generateAlphaNumericCode } from "../compnents/otherCOmps"
import { Timestamp, addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, query, runTransaction, updateDoc, where } from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import reloadImg from "../assets/reload.png";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import copyIcon from "../assets/copy.png";
import { Howl } from "howler";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseInfo, ColorPalette, getUserBalances } from "../global/const";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
//import { getRealBalance } from "../global/util";
import { httpsCallable } from "firebase/functions";
import { copyString, formatTimeAgoMinutes } from "../global/util";
import { getPriceToken } from "../global/apis";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import notificaSOund from "../audioFiles/notification.wav"
const LobbyPage = () =>{

    const [listRooms, setListRooms] = useState([]);

    const prices = [5,10,15,20,25,30,50,100];
    const numLevels = {
        "golf": 16,
    }
    const nav = useNavigate();
    const location = useLocation();
    const gameUrl = location.state.gameUrl;

    const alreadyInGameWaitingData = location.state.alreadyInGameWaitingData;
    const invitedToGame = location.state.invitedToGame;


    const [isWaiting, setIsWaiting] = useState();
    const [partID, setPartID] = useState();
    
    const [isCreateRoom, setIsCreateRoom] = useState(false);
    const [selectedRoomPrice, setSelectedRoomPrice] = useState();
    const [selectedIsOpen, setSelectedIsOpen] = useState(true);
    const [createdCode, setCreatedCode] = useState();

    const [selectedBetChainToken, setSelectBetChainToken] = useState(BaseInfo.ethChainID)

    const [insertedGameCode, setInsertedGameCode] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [isSelectingLevels, setIsSelectingLevel] = useState(true);
    const [selectedLevl, setSelectedLevl] = useState(false);

    const [isOtherPlayerInRoom, setIsOtherPlayerInRoom] = useState(false);
    const [gameDocData, setGameDocData] = useState();

    const [currentTokenPrice, setCurrentTokenPrice] = useState(0);

    const [isSelectingTokenJoinRoom, setIsSelectingTokenJoinRoom] = useState(false);
    const [selectedRoomToJoin, setSelectedRoomToJoin] = useState();

    const notificationSound = new Howl({
        src: [notificaSOund],
        volume: 1, 
      });

    useEffect(() => {
        
        setCurrentTokenPrice(1);
        console.log(selectedBetChainToken);
        getPriceToken(BaseInfo.chainTokensData[selectedBetChainToken]?.symbol).then((p) => setCurrentTokenPrice(p));
    },[selectedBetChainToken]);

    useEffect(() => {
        if(alreadyInGameWaitingData){
            setIsWaiting(true);
            setPartID(alreadyInGameWaitingData.roomID);
            setCreatedCode(alreadyInGameWaitingData.code);
            setSelectedIsOpen(alreadyInGameWaitingData.isOpen);
          /*   if(gameUrl === "race"){
                goToRaceGame(alreadyInGameWaitingData.roomID,alreadyInGameWaitingData.player1)
            }else{ */
                ascoltaArrivoGiocatore(alreadyInGameWaitingData.roomID);
           // }
            setSelectedLevl(alreadyInGameWaitingData.level);
        }else if(invitedToGame){
            setCreatedCode(invitedToGame.code);
            findPrivateRoom(invitedToGame.code);
            setSelectedIsOpen(false);
        }else{
            setIsSelectingLevel(numLevels[gameUrl] != null);
            getListRooms();
        }
        
    },  []);

    async function getListRooms(){
        setListRooms([]);
        const gameNameID = "games";
        const tenMinutesAgoTimestamp = (Date.now() - 15 * 60 * 1000);
        let q = query(
            collection(db, gameNameID),
            where("gameUrl", "==", gameUrl),
            where("state", '==', "waiting"),
            where("isOpen", '==', true),
            where("creationDate", '>=', tenMinutesAgoTimestamp),
        );
        if (gameUrl !== "race") {
            q = query(q, where("players_count", "==", 1));
        }
        q = query(q, limit(10));
        const resList = await getDocs(q);
        let rooms = [];
        resList.docs.forEach(element => {
            rooms.push(
                {
                    ...element.data(),
                    id: element.id,
                }
            )
            
        });
        setListRooms(rooms);
    }

    async function createRoom(price, isOpen){
        if(!price){
            showErrorNotification("Select a price!");
            return;
        }
        if(isOpen == null){
            showErrorNotification("Open or Private?")
            return;
        }
        if(!selectedBetChainToken){
            showErrorNotification("Select a token!");
            return;
        }

        try {
            setIsLoading(true);
            const funct = httpsCallable(functions, "createGameRoom");
            const dat = {
                price: price,
                isOpen: isOpen,
                username: tgUsernameGlob,
                gameUrl: gameUrl,
                level: selectedLevl ?? false,
                token: BaseInfo.chainTokensData[selectedBetChainToken].symbol,
                chain_id: selectedBetChainToken,
            }
            try{
               const r = await funct(dat);
               if(r.data.success === false){
                //showErrorNotification("Error");
                throw "error";
               }
               setPartID(r.data.data.gameID);
               logEvent(analytics, "create_room", {"gameUrl": gameUrl, "price": price, "level": selectedLevl ?? false });
              /*  if(gameUrl === "race"){
                    goToRaceGame(r.data.data.gameID, auth.currentUser.uid);
                    return;
                } */
                console.log(r.data);
                setIsWaiting(true);
                
                setCreatedCode(r.data.data.code);
               
                ascoltaArrivoGiocatore(r.data.data.gameID);
            }catch(e){
                console.log(e);
                showErrorNotification("Error creating room");
            }
            
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    function ascoltaArrivoGiocatore(gameID){
      
        const partitaDocRef = doc(db, "games", gameID);
        const unsubscribe = onSnapshot(partitaDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                
                const partitaData = docSnapshot.data();
                console.log(partitaData);
                setGameDocData(partitaData);
                setCreatedCode(partitaData.code);
                if(partitaData.state == "waiting" && partitaData.player2 != null && partitaData.players != null){ //arriva altro giocatore
                    //check se è host
                    if(partitaData.player1 === auth.currentUser.uid){
                        setIsOtherPlayerInRoom(true);
                        notificationSound.play();
                    }
                }else if(partitaData.state == "in_progress"){ //player1 ha detto di partitre
                    unsubscribe();
                    notificationSound.play();
                    goToGame(gameID, partitaData.gameUrl, partitaData.player1Name,partitaData.player1, partitaData.player2Name,partitaData.player2, partitaData.player1 === auth.currentUser.uid, partitaData.level ?? false, partitaData.price)
                }
               
               
            } else {
                console.log("Il documento non esiste");
                unsubscribe();
                nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
                setIsLoading(false);
                setIsWaiting(false);
            }
            });
    }

    async function startGame(){
        console.log("start game");
        setIsLoading(true);
        const funct = httpsCallable(functions, "startGameRoom");
        const dat = {
            gameID: partID,
        }
        try{
           const r = await funct(dat);
           console.log(r.data);
           if(r.data.success === false){
                //showErrorNotification("Error");
                throw "error";
            }
            console.log(r.data);
        }catch(e){
            showErrorNotification("Error starting room");
            console.log(e);
        }
        setIsLoading(false);
    }
    async function findPrivateRoom(code){
        let q = query(
            collection(db, "games"),
            where("code", "==", code),
            where("state", '==', "waiting"),
            where("isOpen", '==', false),
            where("players_count", "==", 1),
            limit(1),
        );
        const roomRes = await getDocs(q);
        if(roomRes.empty){
            showErrorNotification("No room with this code");
            return;
        }
        const roomFinded = {
            ...roomRes.docs[0].data(),
            id: roomRes.docs[0].id,
        }
        console.log(roomFinded);
        beforeJoiningRoom(roomFinded);
        setSelectedIsOpen(false);
    }

    async function joinGameFromList(id, price){
        if(price / currentTokenPrice > getUserBalances()[selectedBetChainToken].native?.balance ?? 0){
            showErrorNotification("Insufficent balance");
            return;
        }
        console.log(id);
        try {
            setIsLoading(true);
            const funct = httpsCallable(functions, "joinRoomFromList");
            const dat = {
                gameID: id,
                username: tgUsernameGlob,
                chain_id: selectedBetChainToken,
                token: BaseInfo.chainTokensData[selectedBetChainToken].symbol
            }
            const r = await funct(dat);
            logEvent(analytics, "join_room", {gameUrl: gameUrl, level: selectedLevl });
            console.log(r.data);
            setPartID(id);
          /*   if(gameUrl === "race"){
                goToRaceGame(id, r.data.data.p1uid);
                return;
            } */
            ascoltaArrivoGiocatore(id);
            setIsWaiting(true);
            //goToGame(id, r.data.data.p1, r.data.data.p1uid, tgUsernameGlob, auth.currentUser.uid, false, r.data.data.level, r.data.data.price);
          } catch (error) {
            console.error('Errore durante l\'esecuzione della transazione:', error);
          }
          setIsLoading(false);
    }
    function goToGame(gameID,url, p1, p1Uid, p2, p2Uid, isHome, lev, price){
        console.log(url);
        let u;
        if(url === "checkers") u = "checkers";
        else if(url === "race") u = "race";
        else u = "scoreGame";
        logEvent(analytics, "play_pvp", {gameUrl: url, level: lev, price: price });
        nav("/" + u, {state: {
            gameID: gameID,
            player1: p1,
            player2: p2,
            isCasa:isHome,
            p1UID: p1Uid,
            p2UID: p2Uid,
            level: lev,
            price: price,
            url: url,
        }})
    }
    function goToRaceGame(gameID, player1Uid){
        nav("/race", {state: {
            gameID: gameID,
            isCasa: player1Uid == auth.currentUser.uid,
        }})
    }
    async function leaveGame(gameID){
        setIsLoading(true);
        const partitaDocRef = doc(db, "games", gameID);
        await deleteDoc(partitaDocRef);
        logEvent(analytics, "delete_room", {gameUrl: gameUrl });

        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
        setIsLoading(false);
    }
    function beforeJoiningRoom(room){
        setSelectBetChainToken(room.chain_id)
        setSelectedRoomToJoin(room);
        setSelectedLevl(room.level)
        setIsSelectingTokenJoinRoom(true);
    }
    function SingleRoom({room}){
        return(
            <div onClick={() => beforeJoiningRoom(room)} style={{ backgroundColor: "#2c1e35", padding: "22px", borderRadius: "12px", marginTop: "12px"}}>
                
                <RRow horizontalAlign="space-between" verticalAlignment="top">
                    <RColumn horizontalAlign="start">
                    <RText weight="bold" size="20px">{room.player1Name}</RText>
                    {room.level != false && <RText>Level {room.level}</RText>}
                    <RText weight="bold">Players waiting: {(room.players_count ?? 1)}</RText>
                    </RColumn>
                    <RColumn horizontalAlign="end">
                        <RText size="16px">{formatTimeAgoMinutes(room.creationDate)}</RText>
                        <RText weight="bold" size="22px">{room.price}$</RText>
                    </RColumn>
                </RRow>
            </div>
        )
    }
    function RoomList(){
        return(
            <>
              {listRooms.map((room, index) => (
                <SingleRoom room={room} key={index}/>
            ))}
            </>
        )
    }
    if(isLoading){
        return <LoadingScreen/>
    }
    if(isWaiting){
        console.log(gameDocData);
        return(
            <>
            <ToastContainer/>
              <RColumn height="100vh" verticalAlignment="center" horizontalAlign="center">
                <RTitile size="30px" color={ColorPalette.accentColor}>{BaseInfo.gamesName[gameUrl]}</RTitile>
                {selectedLevl && <RText weight="bold" size="18px">Level {selectedLevl}</RText>}
                {gameDocData && gameDocData.players_count < 2 && <RText weight="bold" size="26px">{selectedIsOpen ? "Waiting opponent..." : "Waiting friend..."}</RText>}
                <Space/>
                {!selectedIsOpen && createdCode && <>
                <RText size="18px">Code</RText>
                <RText size="26px" weight="bold">{createdCode}</RText>
                <div onClick={() => copyString("t.me/TheGameHubBot?start=game=" + createdCode, "Invite link copied")}>
                <RRow>
                    <img src={copyIcon} width={"18px"}/>
                    <SpaceHorizontal width="6px"/>
                    <RText>{"t.me/TheGameHubBot?start=game="}{createdCode}</RText>
                   
                </RRow>
                </div>
                <Space/>
                </>}
                {gameDocData && <>
                    <RText weight="bold">Players in room</RText>
                    {gameDocData.players_name.map((player, ind) => (
                    <RText weight="bold" size="20px" key={ind}>{player}</RText>
                ))}
                </>}
                <Space/>
                {gameDocData && gameDocData.players_count > 1 && gameDocData.player1 === auth.currentUser.uid && <PrimaryBtn text={"Start Game"} onClick={startGame}/>}
                <Space/>
               {gameDocData && gameDocData.player1 === auth.currentUser.uid && <SecondaryBtn text={"Close"} onClick={() => leaveGame(partID)}></SecondaryBtn>}
            </RColumn>
          
            </>
        )
    } 
    function onSelectRoomPriceChoose(price){
        if(price / currentTokenPrice > getUserBalances()[selectedBetChainToken].native?.balance ?? 0){
            showErrorNotification("Insufficent balance");
            return;
        }
        setSelectedRoomPrice(price);
    }

    function SingleToken({chain}){
        return(
            <div onClick={() => {setSelectBetChainToken(chain); setSelectedRoomPrice()}} style={{width: null,border: selectedBetChainToken === chain ? "2px solid " + ColorPalette.accentColor : "", padding: "4px 22px", margin: "3px 0px", borderRadius: "10px"}}>
                <RText align={"center"} weight="bold" size="16px">{BaseInfo.chainTokensData[chain]?.name ?? "c"}</RText>
            </div>
        )
    }
    function Prices(){
        return(
            <>
            <RRow horizontalAlign="space-between" width={"70%"}>
                <SingleToken chain={BaseInfo.ethChainID}/>
                <SingleToken chain={BaseInfo.arbChainID}/>
            </RRow>
             <Space/>
              <RColumn width={"80%"}>
             {prices.map((price, index) => (
                    <div onClick={() => onSelectRoomPriceChoose(price)} key={"pri" + index} style={{width: "100%",border: selectedRoomPrice == price ? "2px solid " + ColorPalette.accentColor : "2px solid rgba(0, 0, 0, 0)", padding: "4px", margin: "3px 0px", borderRadius: "10px"}}>
                        <RText color={(price / currentTokenPrice > getUserBalances()[selectedBetChainToken]?.native?.balance ?? 0) ? "lightgray" : "white"} 
                        align={"center"} weight="bold" size="20px">
                            {price}$ - {(price / currentTokenPrice).toFixed(price / currentTokenPrice < 1 ? 5 : 1)} {BaseInfo.chainTokensData[selectedBetChainToken]?.symbol ?? "d"}</RText>
                    </div>
             ))}
            </RColumn>
            </>
        )
    }
    function Levels(){
        const numeri = Array.from({ length: numLevels[gameUrl] }, (_, index) => index + 1);
        return(
            <>
             <RText weight="bold" size="22px" align={"center"}>Select Level</RText>
             <Space/>
              <div style={{flexWrap: "wrap", display:"flex", alignContent:"center", justifyContent: "center"}}>
             {numeri.map((num, index) => (
                    <div onClick={() => setSelectedLevl(num)} key={"pri" + index} style={{width: "40%",border: selectedLevl == num ? "2px solid " + ColorPalette.accentColor : "2px solid rgba(0, 0, 0, 0)", padding: "4px", margin: "3px 0px", borderRadius: "10px"}}>
                        <RText align={"center"} weight="bold" size="20px">Level {num}</RText>
                    </div>
             ))}
            </div>
            </>
        )
    }
    if(isSelectingTokenJoinRoom){
        return(
            <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
             <BackToHomeBtn/>
             <RColumn width={"100%"}>
             <Space height="90px"/>
             <RTitile>{selectedRoomToJoin.player1Name}</RTitile>
             <Space/>
             {selectedRoomToJoin.level && <RText>Level: {selectedRoomToJoin.level}</RText>}
             <RRow horizontalAlign="space-between" width={"70%"}>
                <SingleToken chain={BaseInfo.ethChainID}/>
                <SingleToken chain={BaseInfo.arbChainID}/>
            </RRow>
            <Space/>
            <RText weight="bold" size="22px">{selectedRoomToJoin.price}$ {(selectedRoomToJoin.chain_id === selectedBetChainToken ? selectedRoomToJoin.priceInToken : selectedRoomToJoin.price / currentTokenPrice).toFixed(5)} {BaseInfo.chainTokensData[selectedBetChainToken]?.symbol ?? "l"}</RText>
            <Space height="80px"/>
            <PrimaryBtn onClick={() => joinGameFromList(selectedRoomToJoin.id, selectedRoomToJoin.price)} text={"JOIN"}/>
            </RColumn>
            </>
        )
    }
    if(isCreateRoom){
        return(
            <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
             <BackToHomeBtn/>
             <RColumn width={"100%"}>
             <Space height="90px"/>
             <RRow horizontalAlign="space-between" width={"70%"}>
                <div onClick={() => setSelectedIsOpen(true)} style={{width: "30%",border: selectedIsOpen ? "2px solid " + ColorPalette.accentColor : "", padding: "4px", margin: "3px 0px", borderRadius: "10px"}}>
                <RText align={"center"} weight="bold" size="20px">OPEN</RText>
                </div>
                <div onClick={() => setSelectedIsOpen(false)} style={{width: "30%",border: !selectedIsOpen ? "2px solid " + ColorPalette.accentColor : "", padding: "4px", margin: "3px 0px", borderRadius: "10px"}}>
                <RText align={"center"} weight="bold" size="20px">PRIVATE</RText>
                </div>
             </RRow>
            <Space height="30px"/>
            
            
            {isSelectingLevels && <Levels/>}
            {!isSelectingLevels && <Prices/>}
            <Space/>
            <Space/>
            {isSelectingLevels && <PrimaryBtn onClick={() => setIsSelectingLevel(false)} text={"Next"}/>}
            {!isSelectingLevels && <PrimaryBtn onClick={() => createRoom(selectedRoomPrice, selectedIsOpen)} text={"Create"}/>}
            </RColumn>
            </>
        )
    }
    return(
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        <BackToHomeBtn/>
        <img onClick={getListRooms} src={reloadImg} width={"24px"} style={{position: "fixed", top:"22px", right: "22px"}}/>
        <Space height="80px"/>
        <RColumn padding={"0px 10px"}>
            <RRow width={"100%"}>
                <InputField maxChar={6} value={insertedGameCode} setValue={(e) => setInsertedGameCode(e.target.value.toString().toUpperCase())} placeHolder={"Game code"}/>
                <SpaceHorizontal/>
                <SecondaryBtn onClick={() => findPrivateRoom(insertedGameCode)} text={"Play"}/>
            </RRow>
           
            <RText align={"center"}>or</RText>
            <PrimaryBtn onClick={() => setIsCreateRoom(true)} text={"Create room"}/>
           
            <Space/>
        </RColumn>
        <div style={{padding: "0px 10px"}}>
            <RoomList/>
        </div>
      
        </>
    )
}

export default LobbyPage;