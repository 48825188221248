import { useLocation, useNavigate } from "react-router-dom";
import { BackToHomeBtn, PrimaryBtn, RColumn, RRow, RText, RTitile, SecondaryBtn, Space, SpaceHorizontal } from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { analytics, auth, db } from "../firebase";
import { useEffect, useState } from "react";
import { ColorPalette } from "../global/const";
import { collection, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";

import leadCellImg from "../assets/leaderboard/panel.png";
import bgLeadPanelImg from "../assets/leaderboard/bg.png";
import { getTimeTilFryday, getTimeTilSaturday, isBetweenFridayAndSaturdaySixPMUTC } from "../global/util";
import { logEvent } from "firebase/analytics";

export default function P2eGame() {
    let [h, setH] = useState(window.innerHeight);
    const [isViewingLeaderboard, setIsViewingLeaderbord] = useState(true);
    const nav = useNavigate();
    const location = useLocation();
    const link = location.state.link;
    const url = location.state.url;
    const canPlay = location.state.canPlay;
    const [isMobile, setIsMobile] = useState(true);

    const [leadData, setLeadData] = useState([]);

    const [userLeadData, setUserLeadData] = useState(); //solo se non in top

    const param = `user_id=${auth.currentUser.uid}&game_id=${url}`
    function back(){
        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
    }
    const handleResize = () => {
        setH(window.innerHeight);
        setIsMobile(window.innerWidth < 400);
      };

    useEffect(() => {
      setIsMobile(window.innerWidth < 400);
        window.addEventListener("resize", handleResize);
        getLead();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    async function getLead(){
      try{
        console.log("get lead");
      const leadRef = doc(db, `leaderboards/${url}`);

      const querySnapshot = await getDoc(leadRef);
      const leaderboardData = Object.entries(querySnapshot.data()?.top50 ?? {})
        .sort(([, a], [, b]) => b.score - a.score)
        .slice(0, 5)
        .map(([id, player]) => ({ id, username: player.username, score: player.score }));
   
      setLeadData(leaderboardData);
      if(!(querySnapshot.data()?.top50 ?? {})[auth.currentUser.uid]){
        console.log("non in top 50");
        console.log(auth.currentUser.uid);
        const usrDoc = await getDoc(doc(db,`leaderboards/${url}/scores`, auth.currentUser.uid));
        if(!usrDoc.exists() || usrDoc.data().score == 0 /* || !isLeadDateValid(usrDoc.data().date) */){
          //ultimo
          setUserLeadData({
            username: auth.currentUser.displayName ?? tgUsernameGlob,
            score: 0,
            rank: "-",
          })
          return;
        }
        const q = query(collection(db,`leaderboards/${url}/scores`), where("score", ">",usrDoc.data().score ));
        const pos = await getCountFromServer(q);
        console.log(pos.data().count);
        setUserLeadData({
          username: usrDoc.data()["username"] ?? tgUsernameGlob,
          score:usrDoc.data()["score"],
          rank: pos.data().count + 1,
        })
      }
      
    }catch(e){
      console.log(e);
    }
    }
    function isLeadDateValid(timestampInMillis) {
      const currentDate = new Date();
      const currentDay = currentDate.getUTCDay(); // Giorno della settimana corrente (0 = Domenica, 1 = Lunedì, ..., 6 = Sabato)
  
      const daysPassedSinceLastSaturday = (currentDay + 7 - 6) % 7;
  
      const lastSaturday6PMUTC = currentDate.getTime() - (daysPassedSinceLastSaturday * 24 * 60 * 60 * 1000); // Sottrai giorni in millisecondi
  
      return timestampInMillis > lastSaturday6PMUTC;
  }

  function SinglePlayer({usr, i}){
      return(
       // <div style={{border: "2px solid " + ColorPalette.accentColor, borderRadius: "8px", width: "90%", margin: "4px 0px"}}>
       <div style={{width: isMobile ? "100%": "400px", backgroundImage: `url(${leadCellImg})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain"}}>
          <div style={{padding: "12px 22px", height: "100%"}}>
          <RRow horizontalAlign="space-between" verticalAlignment="center">
            <RRow>
              <RTitile>{i}</RTitile>
              <SpaceHorizontal/>
              <RText weight="bold" color={usr.id === auth.currentUser.uid ? "#78CBDB" : "white"} size="18px">{usr.username.slice(0,22)}</RText>
            </RRow>
            
            <RTitile color="white">{usr.score}</RTitile>
          </RRow>
        </div>
        </div>
      )
  }
  function goToGame(){
    logEvent(analytics, "play_p2e", {gameUrl: url });
    setIsViewingLeaderbord(false);
  }
  if(isViewingLeaderboard){
      return(
        <RColumn verticalAlignment="space-between" height={"100vh"} bgGradint={"linear-gradient(to top right, #0E161B, #2D2D2D)"}>
          <BackToHomeBtn/>
          <RColumn height={"100%"} width={"100%"}>
            <Space/>
            <Space height="80px"/>
            <RTitile>Top 5 players</RTitile>
            <Space height="6px"/>
            {/*{
              isBetweenFridayAndSaturdaySixPMUTC() ?
              <RColumn>
                <RText>Leaderboard closed!</RText>
                <RText>Open in {getTimeTilSaturday()}</RText>
              </RColumn>
             
              :
              <RText>Closing in {getTimeTilFryday()}</RText>
            }
          <Space height="6px"/>*/}

          <div style={{width: isMobile ? "99vw": "422px", height: isMobile ? "99vw" : "422px", backgroundImage: `url(${bgLeadPanelImg})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
            <div style={{padding: isMobile ? "18px 10px" : "24px 10px"}}>
            {leadData.map((dat, ind) => (
              <SinglePlayer key={ind} usr={dat} i={ind + 1}/>
            ))}
          {userLeadData && <>
            
            <div style={{width: "98%", height: "2px", backgroundColor: "gray", margin: "6px 0px"}}></div>
            <SinglePlayer usr={userLeadData} i={userLeadData.rank}/>
          </>}
          </div>
          </div>
        </RColumn>
        {canPlay === false && <RText>Leaderboard closed</RText>}
        {/* !isBetweenFridayAndSaturdaySixPMUTC() && */ canPlay &&  <PrimaryBtn onClick={goToGame} text={"PLAY"}></PrimaryBtn>}
        <Space height="50px"/>
        </RColumn>
      )
  }
  return(
    <>
        <div style={{position: "fixed", top: "30px", left:"10px"}}>
        <SecondaryBtn onClick={back} text={"Exit"}></SecondaryBtn>
        </div>
        <iframe height={h} width="100%" src={link + "?" + param} style={{border: "none"}} ></iframe>
    </>
  )
}