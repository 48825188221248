export class ColorPalette{
    static bgColor = "#0E161B";
    static tabColor = "#2D2D2D";
    static accentColor = "#A84FBA";
}
let _userBalances;
export const getUserBalances = () => _userBalances;

export const setUserBalances = (newBalances) => {
    _userBalances = newBalances;
};

export class BaseInfo{
    static baseInfoDoc = {};
    static p2eGamesNow = [];
    static gamesNow = [];
    static gamesName = {};
    static chainTokensData = {};
    static gameTokenAddress = "0x5fd82ad8a72252a7a449aded99efec7dd8efa690";
    static ethChainID = "0x1";
    static arbChainID = "0xa4b1";
}

export class StatusInfo{
    static data;
}
export const versionGame = "1.0.6";
export const isTest = true;