import { Game } from "./Game";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function CheckersGames(){
    const nav = useNavigate();
    const location = useLocation();
   const {gameID, player1, player2, isCasa, p1UID, p2UID} = location.state;
 
    return(
        <>
        <div className="checkGame">
            <Game id={gameID} p1 = {player1} p2 = {player2} isHome = {isCasa} nav={nav} p1UID={p1UID} p2UID={p2UID}/>
        </div>
       
        </>
    )
}