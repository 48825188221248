import { doc, getDoc } from "firebase/firestore";
import Web3 from "web3";
import { tokeABI } from "./const";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import { differenceInDays, differenceInHours, differenceInMinutes, setHours, setMinutes, setSeconds } from "date-fns";

export function copyString(str, msg){
    navigator.clipboard.writeText(str);
    toast.success(msg, {
        position: "top-left",
        autoClose: 1500,
        hideProgressBar: false,
      });
}

export function getTimeTilFryday(){
    const today = new Date();
    let nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + (6 - today.getDay()));

    nextSunday = setHours(nextSunday, 0);
    nextSunday = setMinutes(nextSunday, 0);
    nextSunday = setSeconds(nextSunday, 0);

    const diffDays = differenceInDays(nextSunday, today);
    const diffHours = differenceInHours(nextSunday, today) % 24;
    const diffMinutes = differenceInMinutes(nextSunday, today) % 60;

    return `${diffDays} days ${diffHours} hours ${diffMinutes} minutes`;
}
export function getTimeTilSaturday(){
  const today = new Date();
  let nextSunday = new Date(today);
  nextSunday.setDate(today.getDate() + (6 - today.getDay()));

  // Imposta l'orario a 23:59:59 per Domenica
  nextSunday = setHours(nextSunday, 18);
  nextSunday = setMinutes(nextSunday, 0);
  nextSunday = setSeconds(nextSunday, 0);

  const diffDays = differenceInDays(nextSunday, today);
  const diffHours = differenceInHours(nextSunday, today) % 24;
  const diffMinutes = differenceInMinutes(nextSunday, today) % 60;

  return `${diffDays} days ${diffHours} hours ${diffMinutes} minutes`;
}
export function isBetweenFridayAndSaturdaySixPMUTC() {
  const date = new Date();
  const fridayMidnight = new Date(date);
  fridayMidnight.setUTCHours(21, 0, 0, 0);
  const nextSaturdaySixPM = new Date(date);
  nextSaturdaySixPM.setUTCHours(18, 0, 0, 0);

  const currentDayOfWeek = date.getUTCDay();
  const isFriday = currentDayOfWeek === 5;
  const isSaturday = currentDayOfWeek === 6;

  if (isFriday && date >= fridayMidnight) {
      return true;
  }
  
  if (isSaturday && date < nextSaturdaySixPM) {
      return true;
  }

  return false;
}
export function formatTimeAgoMinutes(milliseconds) {
  const seconds = Math.floor((Date.now() - milliseconds) / 1000);
  const minutes = Math.floor(seconds / 60);
  
  if (minutes === 0) {
      return "Just now";
  } else if (minutes === 1) {
      return "1 minute ago";
  } else {
      return `${minutes} minutes ago`;
  }
}