import { RColumn, RText, RTitile, Space } from "../compnents/otherCOmps";

export default function FriendsPage(){
    return(
        <RColumn width={"100%"}>
        <Space/>
        <RTitile>FRIENDS</RTitile>
        <Space/>
        <RText>Coming soon</RText>
    </RColumn>
    )
}