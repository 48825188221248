import { Padding, RColumn, RRow, RText, Space, SpaceHorizontal } from "../compnents/otherCOmps"
import exitIcon from "../assets/exit.png";
import { useEffect, useState } from "react";
import { auth, db, logOut } from "../firebase";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { getRealBalance } from "../global/util";
import battleIcon from "../assets/battle.png";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { ToastContainer, toast } from "react-toastify";
import crownImg from "../assets/crown.png";
import { BaseInfo } from "../global/const";

const ProfilePage = ({tgUsername}) =>{
    const [games, setGames] = useState([]);

    const nav = useNavigate();

    useEffect(() => {
        getUserDoc();
    },[]);

    const getUserDoc = async () => {
        const userDoc = doc(db, "users", auth.currentUser.uid);
      
        const gamesRefs = collection(db, userDoc.path, "games");
        const q = query(gamesRefs, 
            orderBy('data', "desc"), // Replace 'data_field_name' with your actual date field name
            limit(10) // Replace 10 with the desired limit
        );
        const querySnapshot = await getDocs(q);
        
        let nGames = [];
        querySnapshot.docs.forEach(element => {
            nGames.push(element.data());
        });
        setGames(nGames);
    };

    function logOutF(){
        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
        logOut();
    }
  
    const SingleGame = ({data}) => {
        const date = format(data.data, 'iii d MMM');
        return(
            <>
            <div style={{width: "96%", backgroundColor: "#2c1e35", borderRadius: "12px", marginTop: "12px"}}>
            <Padding padding={"12px"}>
            <RRow horizontalAlign="space-between">
                <RText weight="bold" size="17px">{BaseInfo.gamesName[data.game]}</RText>
                <RText>{date}</RText>
            </RRow>
            
            <Space/>
            <RRow horizontalAlign="space-evenly">
                <RColumn width={"50%"}>
                    {auth.currentUser.uid == data.winner && <img src={crownImg} width={"30px"}/>}
                    <RText weight="bold" size="20px" align={"center"}>{tgUsername.slice(0,10)}</RText>
                </RColumn>
                <img src={battleIcon} width={"40px"}/>
                <RColumn width={"50%"}>
                 {!data.winner || data.winner == data.opponentUid && <img src={crownImg} width={"30px"}/>}
                    <RText weight="bold" size="20px">{data.opponent.slice(0,10)}</RText>
                </RColumn>
            </RRow>
            <Space/>
            </Padding>
            </div>
            </>
        )
    }
    return(
        <>
        <RColumn width={"100%"}>
            <Space/>
           {/*  <RRow horizontalAlign="space-evenly" width={"100%"}>
                <img src={exitIcon} width={"18px"} onClick={logOutF}/>
            </RRow> */}
         
            <Space/>
            {games.map((element, index) => (
                <SingleGame data={element} key={index}/>
            ))}
        </RColumn>
        </>
    )
}

export default ProfilePage;