import { PrimaryBtn, RColumn, RRow, RText, RTitile, Space, SpaceHorizontal } from "../compnents/otherCOmps";
import { BaseInfo, ColorPalette, StatusInfo, isTest, tokeABI } from "../global/const";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export function HomePage({tgUsername}){
    
    const [gameTypeSelected, setGameTypeSelected] = useState(0);
    const gameTypeName = ["All", "PVP", "P2E", "PVE", "F2P"];
    const nav = useNavigate();

    const [countDownComp, setCountDownComp] = useState("");
   
    let games = [
        {name: BaseInfo.gamesName.ballpol, type: 4, url: "ballpol", link: "ballpol/index.html"},
        {name: BaseInfo.gamesName.golf, type: 1,  url: "golf"},
        {name: BaseInfo.gamesName.goal, type: 1,  url: "goal"},
        {name: BaseInfo.gamesName.race, type: 1,  url: "race", link: "racing/index.html"},
        {name: BaseInfo.gamesName.roulette, type: 3,  url: "roulette"},
        {name: BaseInfo.gamesName.templeDash, type: 2,url: "templeDash", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-temple-dash-mania/1711428315288/index.html"},
        {name: BaseInfo.gamesName.skyHunter, type: 2,  url: "skyHunter", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-atlantic-sky-hunter/1711421649983/index.html"},
        {name: BaseInfo.gamesName.flappyChicken, type: 2, url: "flappyChicken", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-flappy-chicken/1711432698587/index.html"},
        {name: BaseInfo.gamesName.ballJump, type: 2,  url: "ballJump", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-ball-jump/1711421159837/index.html"},
        {name: BaseInfo.gamesName.lateToWork, type: 2, url: "lateToWork", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-late-to-work/1711533179122/index.html"},
        {name: BaseInfo.gamesName.jetSkiRush, type: 2, url: "jetSkiRush", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-jet-ski-rush/1711433978570/index.html"},
        {name: BaseInfo.gamesName.riskyTrainCrossing, type: 2, url: "riskyTrainCrossing", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-risky-train-crossing/1711435639435/index.html"},
        {name: BaseInfo.gamesName.kawaiiJump, type: 2, url: "kawaiiJump", link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-kawaii-jump/1711448571719/index.html"},
        {name: BaseInfo.gamesName.checkers, type: 1, url:"checkers" },
    ]
    if(isTest === false){
        games = games.filter(g => (BaseInfo.p2eGamesNow.includes(g.url) || BaseInfo.gamesNow.includes(g.url) ||  BaseInfo.baseInfoDoc.p2eCompetition.url === g.url));
        if(StatusInfo.data.p2e === false){
            games = games.filter(g => g.type !== 2);
        }
        if(StatusInfo.data.pvp === false){
            games = games.filter(g => g.type !== 1);
        }
        if(StatusInfo.data.pve === false){
            games = games.filter(g => g.type !== 3);
        }
        if(StatusInfo.data.f2p === false){
            games = games.filter(g => g.type !== 4);
        }
    }
    const goToLink = (url, type, link) => {
        if(url === BaseInfo.baseInfoDoc.p2eCompetition.url) type = "p2e-competition"
        if(type == 1)
            nav("/lobby", {state: {gameUrl: url}});
        else if(type == 2)
            nav("/p2e", {state: {link: link, url: url, canPlay: true}});
        else if(type == 3)
            nav("/" + url);
        else if(type == 4)
            nav("/free",  {state: {link: link, url: url}});
        else if(type == "p2e-competition"){
            if(getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.startDate) === false 
            && getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.endDate) !== false ){
                nav("/p2e", {state: {link: link, url: url, canPlay: true}});
            }else{
                nav("/p2e", {state: {link: link, url: url, canPlay: false}});
            }
        }
    }

function SingleGame({title, url, type, link}){
    const bgCol = type !== "p2e-competition" ? `linear-gradient(to left, rgba(14, 22, 27, 1), ${ColorPalette.tabColor})` : `linear-gradient(to left, ${ColorPalette.accentColor}, #31aab8)`;
    const shadow = type !== "p2e-competition" ? null : "0px 0px 30px rgba(11, 153, 224, 0.4)"
    return(
        <>
        <div onClick={() => goToLink(url, type, link)} style={{margin: "20px 0px"}}>
            <div style={{background: bgCol, height: "130px", padding: "0 20px", borderRadius: "10px", border: "solid " + ColorPalette.accentColor + " 2px",boxShadow: shadow }}>
            <RRow horizontalAlign="space-between" verticalAlignment="center" height="100%">
                <RColumn horizontalAlign="start">
                <RText size="18px" weight="bold">{title}</RText>
                <RText size="16px" weight="bold">{gameTypeName[type]}</RText>
                </RColumn>
                
                <img src={"gameIcons/" + url + ".png"} height={"130px"}/>
            </RRow>
            </div>
        </div>
        </>
    );
}

const SingleTypeGameBtn = ({type}) => {
    return (
        <div onClick={() => setGameTypeSelected(type)} style={{backgroundColor: type == gameTypeSelected ? ColorPalette.accentColor : "", padding: "6px 8px", borderRadius: "8px", width: "10%"}}>
            <RText align={"center"}>{gameTypeName[type]}</RText>
        </div>
    )
}

useEffect(() => {
    if(StatusInfo.data.p2eCompetition){
        setInterval(() => {
            setCountDownComp(p => p + 1)
        }, 1000);
    }
});
function getRemainingTimeUntilTarget(targetDateString) {

    const now = new Date(); // Data corrente
    const target = new Date(targetDateString); // Data di destinazione
    const timeDiff = target - now;
  
    if (timeDiff > 0) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        if (hours > 0)
            return `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        else if (minutes > 0)
            return `${minutes} Minutes ${seconds} Seconds`;
        else
            return `${seconds} Seconds`;
        } else {
            return false;
        }
  }
    return (
        <>
            <Space height="20px"/>
            <RTitile align="center" weight="bold" size="26px">GAMES</RTitile>
            <Space/>
            {StatusInfo.data.p2eCompetition &&<> 
            <div style={{padding: "20px"}}>
            <RTitile align={"center"} color={ColorPalette.accentColor}>Daily competition</RTitile>
            {getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.startDate) !== false &&
            <RText align={"center"}>Open in {getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.startDate)}</RText>
            }
            {getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.startDate) === false 
            &&
            getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.endDate) !== false
            &&
            <RText align={"center"}>Close in {getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.endDate)}</RText>}
            {getRemainingTimeUntilTarget(BaseInfo.baseInfoDoc.p2eCompetition.endDate) === false && <RText align={"center"}>Closed</RText>}

            {games.filter(game => game.url === BaseInfo.baseInfoDoc.p2eCompetition.url).map((g, ind) => (
                <SingleGame key={ind} title={g.name} link={g.link} url={g.url} type={"p2e-competition"}/>
            ))}
          
            </div>
            <Space/>
            </>}
            <RRow horizontalAlign="space-evenly" width={"100%"}>
                {gameTypeName.map((tit, ind) => (
                    <SingleTypeGameBtn key={tit} type={ind}/>
                ))}
            </RRow>
            <div style={{width: "100%"}}>
            <div style={{padding: "0 20px"}}>
                {games.filter(g => gameTypeSelected === 0 ? g : g.type == gameTypeSelected).map((game, index) => (
                    <SingleGame link={game.link} title={game.name} url={game.url} type={game.type} key={ "game" + index}/>
                ))}
            </div>
            {games.filter(g => gameTypeSelected === 0 ? g : g.type == gameTypeSelected).length === 0 &&
                <RColumn>
                    <Space height="46px"/>
                    <RText>Coming soon</RText>
                </RColumn>
            }
            </div>
        </>
    );
}

