import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryBtn, RColumn, RRow, RText, RTitile, ScorePanel, Space } from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import React, { useEffect, useRef, useState } from "react";
import { off, onDisconnect, onValue, ref, set } from "firebase/database";
import { auth, rdb } from "../firebase";
import { ColorPalette, isTest } from "../global/const";

const urlToIndexHtml = {
    "golf": "miniGolf/index.html",
    "goal": "goalkik/index.html",
}
const winConditionPerUrl = {
    "golf": "<",
    "goal": ">",
}
export default function ScoreGame(){
    let [h, setH] = useState(window.innerHeight);
    const nav = useNavigate();
    const location = useLocation();
    const {gameID, player1, player2, isCasa, p1UID, p2UID, level, price, url} = location.state;

    const [opponentScore, setOpponentScore] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [opponentFinished, setOpponentFinished] = useState(false);
    const [userFinished, setUserFinished] = useState(false);
    const [winnerdDecided, setWinnerDecided] = useState(false);
    const [opponentConnected, setOpponentConnected] = useState(true);

    const timeTimerStart = 30;

    const partitaRef = ref(rdb, "active_games/games_score/"+ gameID);
    const levelRef = ref(rdb, "active_games/games_score/"+ gameID + "/level");
    const connectionRef =  ref(rdb, "active_games/games_score/"+ gameID + "/connected");
    const singleConnectedRef = ref(rdb, "active_games/games_score/"+ gameID + "/connected/" + auth.currentUser.uid);
    const [timer, setTimer] = useState(timeTimerStart);
    const [isTimerPanelOpen, setIsTimerPanelOpen] = useState(false);
    const [alreadyOpenTimerPanel, setAlreadyOpenTimerPanel] = useState(false);


   

    let isFirstTimeLvl = true;

    function back(){
        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
    }
    const handleResize = () => {
        setH(window.innerHeight);
    };
    useEffect(() => {
      
      onValue(partitaRef, onGameChanged);
      onValue(levelRef, onLevelChanged);
      checkDisconnection();
      onValue(connectionRef, onConnectedChange);

    },[]);
    const timerIntervalRef = useRef(null);
    useEffect(() => {
      timerIntervalRef.current = setInterval(() => {
        setTimer(prevSeconds => prevSeconds - 1);
      }, 1000);
  
      return () => clearInterval(timerIntervalRef.current);
    }, []);

    useEffect(() => {

      if(timer <= 20 && isTimerPanelOpen === false && alreadyOpenTimerPanel === false){
        setIsTimerPanelOpen(true);
        setAlreadyOpenTimerPanel(true);
      }
      if(timer <= 0){
        clearInterval(timerIntervalRef.current);
        console.log(timerIntervalRef.current);
        console.log("lose");
        setTimerDisconnect();
      }
    }, [timer]);

    async function setTimerDisconnect(){
    clearInterval(timerIntervalRef.current);
    setIsTimerPanelOpen(false);
     await set(singleConnectedRef, false);
    }
    
    function checkDisconnection(){
      onDisconnect(connectionRef).update({[auth.currentUser.uid]: false});
    }
    const onConnectedChange = (snapshot) => {
      if(snapshot.val() == null) {
        onDisconnect(connectionRef).cancel();
        off(connectionRef, "value", onConnectedChange);
        return;
      }
      if(snapshot.val()[opponentUid()] == null) return;
      setOpponentConnected(snapshot.val()[opponentUid()]);
      if(snapshot.val()[opponentUid()] === false){
        clearInterval(timerIntervalRef.current);
      }
    }
    async function postScore(s){
      const r = ref(rdb, "active_games/games_score/" + gameID + "/points/" + auth.currentUser.uid);
      await set(r,s);
    }
    async function postFinished(){
      clearInterval(timerIntervalRef.current);
      const r = ref(rdb, "active_games/games_score/"+ gameID + "/finished/" + auth.currentUser.uid);
      await set(r,true);
    }
    async function postTiro(){
      setTimer(timeTimerStart);
      setAlreadyOpenTimerPanel(false);
      const r2 = ref(rdb, "active_games/games_score/" + gameID + "/lastMoveTime/" + auth.currentUser.uid);
      await set(r2, Date.now());
    }
    function opponentUid(){
      return p1UID == auth.currentUser.uid ? p2UID : p1UID;
    }
    const onGameChanged = (snapshot) => {    
        if(snapshot.val() == null) {
          onGameDelated();
          off(partitaRef, "value", onGameChanged);
          return;
        }
        const oppFin = snapshot.child("finished").child(opponentUid()).val() ?? false;
        const usrFin = snapshot.child("finished").child(auth.currentUser.uid).val() ?? false;
        const oppScore = snapshot.child("points").child(opponentUid()).val();
        const usrScore = snapshot.child("points").child(auth.currentUser.uid).val();
        setOpponentFinished(oppFin);
        setUserFinished(usrFin);
        setOpponentScore(oppScore);
        setUserScore(usrScore);
    }
    const onLevelChanged = (snapshot) =>{
      if(isFirstTimeLvl){
        isFirstTimeLvl = false;
        return;
      }
      if(snapshot.val() == null) {
        off(levelRef, "value", onLevelChanged);
        return;
      }
      if(snapshot.child("num").val() == level) {
        console.log("same lvl");
       // return;
      }
      const iframe = document.getElementById('gameFrame');
      iframe.contentWindow.location.reload();
      iframe.onload = () => {
        iframe.contentWindow.levelToPlay = snapshot.child("num").val();
      };
      
    }
    function onGameDelated(){
      onDisconnect(connectionRef).cancel();
      setWinnerDecided(true); 
    }
   
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        const iframe = document.getElementById('gameFrame');
        iframe.contentWindow.levelToPlay = level;
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    useEffect(() => {
      const riceviMessaggio = (event) => {

        if (event.data.type === ('score_' + url)) {
          const numScore = event.data.numScore;
          postScore(numScore);
        }
        if (event.data.type === ('finished_') + url) {
          console.log("fineee");
          postFinished();
        }
        if (event.data.type === ('move_' + url)) {
          
          postTiro();
        }
      };
  
      window.addEventListener('message', riceviMessaggio);
  
      return () => {
        window.removeEventListener('message', riceviMessaggio);
      };
    }, []);

    function TimerPanel(){
      return(
        <div style={{position: "fixed", top: "20vh", left: "25vw", width: "50vw", height: "40vh", borderRadius: "20px", zIndex: 30, background: 'linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))' }}>
          <RColumn height={"100%"} verticalAlignment="center" horizontalAlign="center" width={"100%"}>
            <RTitile>Are you there?</RTitile>
            <Space/>
            <RText>Make a move in {timer} seconds or you will lose!</RText>
            <Space/><Space/>
            <PrimaryBtn text={"OK"} onClick={() => setIsTimerPanelOpen(false)}/>
          </RColumn>
        </div>
      )
    }
 
    function SidePanel (){
      return(
        <>
        {isTest && !userFinished && opponentConnected && <div style={{position: "fixed", bottom: "100px", zIndex: 10, left: "18px"}}>
        <RText size="18px" weight="bold">{timer}</RText>
        </div>}
       
        <ScorePanel player={isCasa ? player2 : player1} score={opponentScore} pos="bottom"/>
        </>
      )
    }
    function winTxt(){
        if(winConditionPerUrl[url] == "<"){
            if(userScore < opponentScore) return "YOU WIN!";
            else if(userScore > opponentScore) return "YOU LOSE";
        }else{
            if(userScore > opponentScore) return "YOU WIN!";
            else if(userScore < opponentScore) return "YOU LOSE";
        }
        return "";
    }
    function OpponentDisconnectedWid(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn>
          <Space/>
        <RText align={"center"} weight="bold" size="24px">Opponent disconnected</RText>       
        <Space/>
        <Space/>
        <Space/>
        <RTitile color={ColorPalette.accentColor} weight="bold" size="26px">YOU WIN</RTitile>
        <RText weight="bold">+{price} $</RText>
        <Space/>
        <PrimaryBtn onClick={back} text={"Close"}/>
        </RColumn>
        </div>
      )
    }
    function TimerDisconnectWid(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn>
          <Space/>
        <RText align={"center"} weight="bold" size="24px">You disconnected</RText>       
        <Space/>
        <Space/>
        <RTitile color={ColorPalette.accentColor} weight="bold" size="26px">YOU Lose</RTitile>
        <Space/>
        <PrimaryBtn onClick={back} text={"Close"}/>
        </RColumn>
        </div>
      )
    }
    function FinishPanel(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn>
          <Space/>
        <RText align={"center"} weight="bold" size="24px">Level finished</RText>
        {!opponentFinished && <RText align={"center"} weight="bold" size="18px">Waiting opponent...</RText>}
        <Space/>
        <Space/>
        <RRow width={"100%"} horizontalAlign="space-evenly">
          <RColumn>
          <RText align={"center"} weight="bold" size="20px">You</RText>
          <RTitile>{userScore}</RTitile>
          </RColumn>
          <RColumn>
          <RText align={"center"} weight="bold" size="20px">{isCasa ? player2 : player1}</RText>
          <RTitile>{opponentScore}</RTitile>
          </RColumn>
        </RRow>
        <Space/>
        <Space/>
        {!winnerdDecided && opponentFinished && <RText>winner calculation...</RText>}
        {winnerdDecided && <RTitile color={userScore < opponentScore ? ColorPalette.accentColor : "white"} weight="bold" size="26px">{winTxt()}</RTitile>}
        {((winConditionPerUrl[url] == "<" && userScore < opponentScore) || (winConditionPerUrl[url] == ">" && userScore > opponentScore)) && winnerdDecided  && <RText weight="bold">+{price} $</RText>}
        <Space/>
        {opponentFinished && userScore == opponentScore && <RText color={ColorPalette.accentColor} weight="bold" size="20px">Same score, loading another level...</RText>}
        {winnerdDecided && <PrimaryBtn onClick={back} text={"Close"}/>}
        </RColumn>
        </div>
      )
    }
    return(
        <>
        <iframe id="gameFrame" width="100%" height={h - 15} src={urlToIndexHtml[url]} style={{border: "none"}} ></iframe>
       {isTimerPanelOpen && timer > 0 && <TimerPanel/>}
       {timer <= 0 && <TimerDisconnectWid/>}
        <SidePanel/>
        {userFinished && opponentConnected && <FinishPanel/>}
        {!opponentConnected && <OpponentDisconnectedWid/>}
       {/*  <div style={{position: "fixed", left: "10px", bottom: "10px", zIndex: 4}}>
            <dvi onClick={back}>Exit</dvi>
        </div> */}
        </>
    )
}