import { InputField, PrimaryBtn, RColumn, RTitile, Space, RRow, RText } from "../compnents/otherCOmps";
import { ColorPalette } from "../global/const";
import headerImg from "../assets/headerbBG.jpeg";
import logoImg from "../assets/logo.png";
import { useState } from "react";
import { auth, createAccount, functions } from "../firebase";
import {signInWithEmailAndPassword} from "firebase/auth"
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
import { httpsCallable } from "firebase/functions";

export default function LoginPage({telegram_id, telegram_username, ref_code}){
    const [passw, setPassw] = useState("");

    const [isCreateAcc, setIsCreateAcc] = useState(true);
    const refCode = ref_code;

    console.log(telegram_username);
    const handlePassw = (event) => {
        setPassw(event.target.value)
    }

    const creaAcc =  async () => {
        try{
            await createAccount(telegram_id, passw, telegram_username);
            if(refCode && refCode.length > 4){
                const funct = httpsCallable(functions, "updateReferral");
                try{
                    await funct({code: refCode});
                }catch(e){
                    console.log(e);
                } 
            }
        }catch(e){
            showErrorNotification("Account already exist");
        }
    }
    const login = async () => {
        const mail = telegram_id + "@mail.com";
        try{
            await signInWithEmailAndPassword(auth, mail, passw);
        }catch(e){
            console.log(e);
            showErrorNotification("Wrong password");
        }
    }
    return(
        <>
        <div style={{backgroundColor: ColorPalette.tabColor, padding: "15px 30px", backgroundImage: `url(${headerImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                <RRow height="100%" horizontalAlign="space-between">
                    <img src={logoImg} height="70px"></img>
                    <RColumn>
                        <RText weight="bold" size="18px">{telegram_username}</RText>
                    </RColumn>
                    
                </RRow>
                
            </div>
            <RColumn>
            <Space height="100px"/>
            <RColumn horizontalAlign="center" width={"98%"}>

                <RTitile>{isCreateAcc ? "Choose a password" : "Enter your password"}</RTitile>
                <Space/>
                <InputField value={passw} setValue={handlePassw} maxChar={15} placeHolder={"Password"}/>
                <Space/>

             {/*    {isCreateAcc && <>
                    <InputField value={refCode} setValue={(e) => setRefCode(e.target.value)} maxChar={15} placeHolder={"Referral code"}/>
                    <Space/>
                </>} */}
                <RText>{isCreateAcc ? "Already have an account?" : "Don't have an account?"} <span onClick={() => setIsCreateAcc(i => !i)} style={{fontWeight: "bold"}}>{isCreateAcc ? "Log in" : "Create one"}</span></RText>
                <Space/>
                <PrimaryBtn text={isCreateAcc ? "Create account" : "Log in"} onClick={isCreateAcc ? creaAcc : login}></PrimaryBtn>
            </RColumn>
            </RColumn>
          
        </>
    )
}