import { useEffect, useState } from 'react';
import './App.css';
import { HomePage } from './home/homePage';
import { auth, db} from "./firebase";
import {BrowserRouter, Routes, Route, MemoryRouter, useNavigate, useLocation} from 'react-router-dom'
import LoginPage from './home/loginPage';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePage from './profile/profilePage';
import LobbyPage from './lobby/lobbyPage';
import CheckersGames from './checkers/checkersGame';
import { RColumn, RText, Space } from './compnents/otherCOmps';
import logoImg from "./assets/logo.png";
import ScoreGame from './scores_games/scoresGames';
import RouletteGame from './roulette/roulette';
import P2eGame from './p2eGames/p2eGames';
import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore';
import TabController from './home/tabController';
import RacingGame from './racing/racingGame';
import { showErrorNotification, showSuccessNotification } from './compnents/errorMessagesComp';
import F2PGames from './f2pGames/f2pGames';
import { BaseInfo, StatusInfo, isTest, setUserBalances, versionGame } from './global/const';
import NoActivePage from './home/noActivePage';

//https://crabsgames.com/?username=JJRic222#tgShareScoreUrl=tg%3A%2F%2Fshare_game_score%3Fhash%3DA6A_UdWJKd4it0mMVGhOKPwYQS3SOsBJq0aL42Xbfrnn9UynfJcd_K9sXZ_uCtQi

export var tgUsernameGlob = "";
export var telegramID;


export default function Starting() {
  const [tgUsername, setTgUsername] = useState(null);
  const [tgID, setTgId] = useState(null);
  const [isLogged, setIsLogged] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const nav = useNavigate();
  const [isNoActive, setIsNoActive] = useState(false);
  const [noActiveMsg, setNoActiveMsg] = useState("");

  useEffect(() => {
    
    if(location.pathname !== "/"){
      nav("/");
    }
    
    const unsubscribe = auth.onAuthStateChanged( async (authUser) => {
      setIsLoading(true);
      if (authUser) {
        const urlObject = new URL(url);
        console.log("auu");
        telegramID = authUser.email.split("@mail.com")[0];
        tgUsernameGlob = authUser.displayName ?? (urlObject.searchParams.get('username') ?? "");
        setTgUsername(tgUsernameGlob);
        console.log(tgUsernameGlob);
        setTgId(telegramID);
        setIsLogged(true);
        loadData(authUser.uid);
      } else {     
        console.log("no auu");
          const url = window.location.href;
          const urlObject = new URL(url);
          const username = urlObject.searchParams.get('username') ?? "Player_" + Math.random(100000, 99999);
          const id = urlObject.searchParams.get("id");
          
        if(username){
          
          tgUsernameGlob = username;
          setTgUsername(username);
         
          setIsLogged(false);
          setIsLoading(false);
        }
        if(id){
          telegramID = id.toString();
          setTgId(id);
        }
      }
    });
    const url = window.location.href;
    const urlObject = new URL(url);
    const refCode = urlObject.searchParams.get("refCode");
    setRefCode(refCode);
    const rid = urlObject.searchParams.get("roomID");
    if(rid && rid.length === 6){
      goToRoomInvited(urlObject.searchParams.get("roomID"));
    }

    return () => {
      unsubscribe();
    };

  },[]);

  async function loadData(uid){
    //get base info
    const statusD = await getDoc(doc(db, "aBase/status"));
    StatusInfo.data = statusD.data();
    if(isTest === false && StatusInfo.data.active === false){
      setIsNoActive(true);
      setNoActiveMsg(StatusInfo.data.no_active_msg);
      return;
    }
    const userDataD = await getDoc(doc(db, "users", uid));
    if(userDataD.exists()){
      setUserBalances(userDataD.data()["balances"] ?? {});
    }
    const baseInfoD = await getDoc(doc(db, "aBase/baseInfo"));
    BaseInfo.baseInfoDoc = baseInfoD.data();
    BaseInfo.p2eGamesNow = baseInfoD.data().p2eGamesNow;
    BaseInfo.gamesNow = baseInfoD.data().gamesNow;
    BaseInfo.gamesName = baseInfoD.data().gamesName;
    BaseInfo.chainTokensData = baseInfoD.data().chainTokensData;
    //check if is waiting room
    const tenMinutesAgoTimestamp = (Date.now() - 15 * 60 * 1000);
    const gamesRef = collection(db, "games");
    const q = query( gamesRef,where("players", "array-contains", auth.currentUser.uid),
     where("creationDate", ">=", tenMinutesAgoTimestamp),limit(1));
    const querySnapshot = await getDocs(q);
    if(!querySnapshot.empty){
      const gameDoc = querySnapshot.docs[0];
      console.log(gameDoc.data());
      nav("/lobby", {state: {
        gameUrl: gameDoc.data().gameUrl,
        alreadyInGameWaitingData: {
            roomID: gameDoc.id,
            code: gameDoc.data().code,
            level: gameDoc.data().level ?? false,
            isOpen: gameDoc.data().isOpen,
            player1: gameDoc.data().player1,
        }
      }})
    }
    setIsLoading(false);
  }

  async function goToRoomInvited(code){
    nav("/lobby", {state: {
      invitedToGame: {
        code: code,
      }
    }});
  }

  if(isNoActive === true){
    return <NoActivePage msg={noActiveMsg}/>
  }
 
  if(isLogged === null || isLoading){
    return (
      <RColumn width={"100%"} height={"100vh"} verticalAlignment='center'>
        <img src={logoImg} width={"90px"}/>
        <Space/>
        <RText size="12px">v {versionGame}</RText>
      </RColumn>
    )
  }
  if(isLogged === false){
    return <LoginPage telegram_id={tgID} telegram_username={tgUsername} ref_code={refCode}></LoginPage>
  }
  return (
    <>
       {/* <button onClick={logOut}>o</button> */}
       <Routes>
        <Route path="/" element={<TabController tgUsername={tgUsername}/>} />
        <Route exact path="/lobby" element={<LobbyPage/>}/>
        <Route exact path="/checkers" element={<CheckersGames/>}/>
        <Route exact path="/race" element={<RacingGame/>}/>
        <Route exact path="/p2e" element={<P2eGame/>}/>
        <Route exact path="/free" element={<F2PGames/>}/>
        <Route exact path="/scoreGame" element={<ScoreGame/>}/>
        <Route exact path="/roulette" element={<RouletteGame/>}/>
        <Route exact path="/profile" element={<ProfilePage tgUsername={tgUsername}/>}/>


      </Routes>
    </>
  );
}