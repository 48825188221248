import "./comps.css";
import "../App.css"
import backIcon from "../assets/previous.png";
import { useNavigate } from "react-router-dom";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { ColorPalette } from "../global/const";
import {SpinningCircles} from 'react-loading-icons';

export  function Space({height = "20px"}){
    return (
        <div style={{height: height}}></div>
    )
}
export  function SpaceHorizontal({width = "10px"}){
  return (
      <div style={{width: width}}></div>
  )
}
export function SecondaryBtn({text, onClick}){
    return (
        <button className="secondary-button" onClick={onClick}>
          {text}
        </button>
      );
}

export function PrimaryBtn({text, _width = null, onClick}){
    return (
        <button className="primary-button" style={{width: _width}} onClick={onClick}>
          {text}
        </button>
      );
}

export function RColumn({padding = null, children, horizontalAlign = "center",verticalAlignment = "start", height = null, width = null, bgColor = null, bgGradint = null}) {
  const style = {
    height: height,
    width: width,
    display: "flex",
    alignItems: horizontalAlign,
    justifyContent: verticalAlignment,
    flexDirection: "column",
    padding: padding,
    backgroundColor: bgColor,
    background: bgGradint,
  }
  return (
    <div style={style}>
      {children}
    </div>
  );
}
export function RRow({children, horizontalAlign = "start" ,verticalAlignment = "center", width = null, height = null}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexDirection: "row",
  }
  return (
    <div style={style}>
      {children}
    </div>
  );
}

export function RText({children, size = "16px", weight = "normal", color = "white", align = null}){
  return (
    <div style={{color: color, fontSize: size, fontWeight:weight, textAlign: align}}>
      {children}
    </div>
  )
}

export function RTitile({children,size = "22px", weight = "normal", color = "white", align = null}){
  // font-family: 'Righteous', sans-serif;
  return (
    <div className="tit" style={{color: color, fontSize: size, fontWeight:weight, textAlign: align}}>
      {children}
    </div>
  )
}

export function InputField({placeHolder, setValue, value, maxChar = null}){

  return(
    <input maxLength={maxChar} value={value} onChange={setValue} placeholder={placeHolder} style={{ margin: "6px 0px",color: "white" , fontSize: "16px", padding: "16px 20px", boxSizing: "border-box", borderRadius: "12px", width: "100%", border: "none", outline: "none", backgroundColor:"black"}}/>
  );
}

export function BackToHomeBtn(){
  const nav = useNavigate();
  function back(){
    nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
  }
  return <div style={{position: "fixed", top:"22px", left: "22px"}}>
    <img src={backIcon} width={"22px"} onClick={back}/> 
    </div>
}

export function generateAlphaNumericCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}
export const LoadingScreen = () => {
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', background: ColorPalette.bgColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <RText weight="bold" size="20px">Loading...</RText>
      <SpaceHorizontal/>
      <SpinningCircles/>
    </div>
  )
}

export const ScorePanel = ({player, score, pos = "top"}) => {
    return(
      <div style={{zIndex: 3, position: "fixed", top: pos == "top" ? "12px" : "", left: "12px", bottom: pos == "bottom" ? "18px" : "",width: "140px", height: "80px", background: 'linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))', borderRadius: "12px"}}>
        <RColumn height={"100%"} verticalAlignment="center">
          <RText weight="bold">{player?.slice(0,10)}</RText>
          <RTitile weight="bold" size="20px">{score}</RTitile>
        </RColumn>
      </div>
    )
  
}
export const Padding = ({children, padding}) => {
  return(
    <div style={{padding: padding}}>
      {children}
    </div>
  )
}